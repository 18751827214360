<template>
  <div id="mallList" class="fixedMode">
    <div class="fixedTop">
      <div class="topSearchWrap">
        <input type="text" class="topSearchInput" placeholder="搜索相关商品关键词" />
      </div>
    </div>
    <div class="mainScroll">
      <div class="goodsListWrap">
        <div class="title">
          10元抵扣券专区
          <span class="moreLink">更多 ></span>
        </div>
        <div class="goodsList">
          <div class="aGoods">
            <div class="goodsImg">
              <img
                src="https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=1665190597,2593156202&fm=26&gp=0.jpg"
                alt
              />
              <div class="goodsPrizeTag">
                <span class="prizeTag">券后价</span>
                <span class="newPrize">29.9</span>
              </div>
            </div>
            <p class="goodsName">印花帆布包</p>
            <p class="goodsOldPrize">￥39.90</p>
          </div>
          <div class="aGoods">
            <div class="goodsImg">
              <img
                src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg002.hc360.cn%2Fm8%2FM05%2F29%2F12%2FwKhQplURMuyEAp7kAAAAAHrJRjI786.jpg&refer=http%3A%2F%2Fimg002.hc360.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1613272157&t=73869695f4dc239b6a0ba032f8898bf4"
                alt
              />
              <div class="goodsPrizeTag">
                <span class="prizeTag">券后价</span>
                <span class="newPrize">29.9</span>
              </div>
            </div>
            <p class="goodsName">云朵女孩保温杯</p>
            <p class="goodsOldPrize">￥39.90</p>
          </div>
          <div class="aGoods loading">
            <div class="goodsImg"></div>
            <p class="goodsName gapLoad">--</p>
            <p class="goodsOldPrize gapLoad">--</p>
          </div>
          <div class="aGoods loading">
            <div class="goodsImg"></div>
            <p class="goodsName gapLoad">--</p>
            <p class="goodsOldPrize gapLoad">--</p>
          </div>
          <div class="aGoods loading">
            <div class="goodsImg"></div>
            <p class="goodsName gapLoad">--</p>
            <p class="goodsOldPrize gapLoad">--</p>
          </div>
          <div class="aGoods loading">
            <div class="goodsImg"></div>
            <p class="goodsName gapLoad">--</p>
            <p class="goodsOldPrize gapLoad">--</p>
          </div>
        </div>
      </div>
      <div class="goodsListWrap">
        <div class="title">
          15元抵扣券专区
          <span class="moreLink">更多 ></span>
        </div>
        <div class="goodsList">
          <div class="aGoods loading">
            <div class="goodsImg"></div>
            <p class="goodsName gapLoad">--</p>
            <p class="goodsOldPrize gapLoad">--</p>
          </div>
          <div class="aGoods loading">
            <div class="goodsImg"></div>
            <p class="goodsName gapLoad">--</p>
            <p class="goodsOldPrize gapLoad">--</p>
          </div>
          <div class="aGoods loading">
            <div class="goodsImg"></div>
            <p class="goodsName gapLoad">--</p>
            <p class="goodsOldPrize gapLoad">--</p>
          </div>
          <div class="aGoods loading">
            <div class="goodsImg"></div>
            <p class="goodsName gapLoad">--</p>
            <p class="goodsOldPrize gapLoad">--</p>
          </div>
          <div class="aGoods loading">
            <div class="goodsImg"></div>
            <p class="goodsName gapLoad">--</p>
            <p class="goodsOldPrize gapLoad">--</p>
          </div>
          <div class="aGoods loading">
            <div class="goodsImg"></div>
            <p class="goodsName gapLoad">--</p>
            <p class="goodsOldPrize gapLoad">--</p>
          </div>
        </div>
      </div>
      <div class="goodsListWrap">
        <div class="title">
          20元抵扣券专区
          <span class="moreLink">更多 ></span>
        </div>
        <div class="goodsList">
          <div class="aGoods loading">
            <div class="goodsImg"></div>
            <p class="goodsName gapLoad">--</p>
            <p class="goodsOldPrize gapLoad">--</p>
          </div>
          <div class="aGoods loading">
            <div class="goodsImg"></div>
            <p class="goodsName gapLoad">--</p>
            <p class="goodsOldPrize gapLoad">--</p>
          </div>
          <div class="aGoods loading">
            <div class="goodsImg"></div>
            <p class="goodsName gapLoad">--</p>
            <p class="goodsOldPrize gapLoad">--</p>
          </div>
          <div class="aGoods loading">
            <div class="goodsImg"></div>
            <p class="goodsName gapLoad">--</p>
            <p class="goodsOldPrize gapLoad">--</p>
          </div>
          <div class="aGoods loading">
            <div class="goodsImg"></div>
            <p class="goodsName gapLoad">--</p>
            <p class="goodsOldPrize gapLoad">--</p>
          </div>
          <div class="aGoods loading">
            <div class="goodsImg"></div>
            <p class="goodsName gapLoad">--</p>
            <p class="goodsOldPrize gapLoad">--</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mallList",
};
</script>

<style lang="scss" scoped>
.fixedTop {
  background: #151619;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  .topSearchWrap {
    width: 488px;
    background: #fff url(~@/assets/icons/search.png) 17px 15px / 26px
      no-repeat;
    height: 56px;
    box-sizing: border-box;
    border-radius: 56px;
    padding: 0 25px 0 66px;
    .topSearchInput {
      height: 56px;
      border: none;
      padding: 0;
      margin: 0;
      width: 100%;
      display: block;
      font-size: 28px;
      line-height: 56px;
      &::placeholder {
        color: #999;
      }
    }
  }
}
.goodsListWrap {
  padding: 20px 0 20px 30px;
  background: #fff;
  .title {
    height: 45px;
    line-height: 45px;
    display: flex;
    justify-content: space-between;
    font-size: 30px;
    font-weight: bold;
    padding-right: 30px;
    .moreLink {
      font-size: 22px;
      color: #999;
      font-weight: normal;
    }
  }
  .goodsList {
    font-size: 0;
    .aGoods {
      width: 215px;
      margin-right: 23px;
      display: inline-block;
      vertical-align: top;
      margin-top: 20px;
      padding-bottom: 6px;
      .goodsImg {
        height: 215px;
        overflow: hidden;
        border-radius: 10px;
        position: relative;
        margin-bottom: 16px;
        .goodsPrizeTag {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          height: 45px;
          align-items: flex-end;
          background: linear-gradient(
            rgba(255, 255, 255, 0) 25%,
            #272626 25%,
            #272626 100%
          );
          z-index: 1;
          .prizeTag {
            flex: 1;
            line-height: 34px;
            font-size: 22px;
            text-align: center;
            color: #e1c297;
            overflow: hidden;
            display: block;
            white-space: nowrap;
          }
          .newPrize {
            height: 100%;
            line-height: 48px;
            display: block;
            overflow: hidden;
            min-width: 91px;
            background: url(~@/assets/mall/prizetagbg.png) center / 100% 100%
              no-repeat;
            color: #fff;
            font-weight: bold;
            font-size: 32px;
            text-align: center;
            padding: 0 3px 0 0;
            &::before {
              content: "￥";
              font-size: 40px;
              transform: scale(0.5);
              display: inline-block;
              transform-origin: right center;
              vertical-align: text-top;
            }
          }
        }
      }
      .goodsName {
        line-height: 35px;
        font-size: 26px;
        height: 35px;
      }
      .goodsOldPrize {
        line-height: 30px;
        height: 30px;
        font-size: 26px;
        margin-top: 7px;
      }
    }
  }
  & + .goodsListWrap {
    border-top: 20px solid #efefef;
    padding-top: 30px;
  }
}
</style>